.dealer-reports-outter-container {
  height: 100%;
  width: 98%;
  margin: 0 auto; }
  .dealer-reports-outter-container .reports-header {
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 10px; }
    .dealer-reports-outter-container .reports-header .reports-heading {
      color: #34792c;
      font-size: 2em;
      font-weight: 400;
      letter-spacing: 1.5px;
      margin-bottom: 0;
      text-align: left; }
    .dealer-reports-outter-container .reports-header .reports-days-dropdown {
      position: relative; }
    .dealer-reports-outter-container .reports-header .dropdowns-container {
      display: flex;
      margin-right: 35px; }
      .dealer-reports-outter-container .reports-header .dropdowns-container .dropdown-container {
        display: inline-block;
        margin-left: 1em; }
        .dealer-reports-outter-container .reports-header .dropdowns-container .dropdown-container:first-child {
          margin-left: 0em; }
  .dealer-reports-outter-container .dealers-reports-container {
    height: calc(100% - 130px);
    overflow-y: auto; }
    .dealer-reports-outter-container .dealers-reports-container .report-heading {
      color: #34792c;
      font-size: 1.5em;
      font-weight: 400;
      letter-spacing: 1.5px;
      margin-bottom: 0;
      display: block; }
      .dealer-reports-outter-container .dealers-reports-container .report-heading .button {
        margin-left: 10px; }
    .dealer-reports-outter-container .dealers-reports-container .full-width-margin-auto {
      margin: auto !important; }
      .dealer-reports-outter-container .dealers-reports-container .full-width-margin-auto.simple-bar-chart .recharts-tooltip-wrapper {
        z-index: 1; }
    .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .selection.dropdown {
      margin-top: 5px;
      margin-right: 15px; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .selection.dropdown:not(:first-child) {
        min-width: 20em; }
    .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table {
      width: 100%; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table.hasLongRemark .rt-tbody .rt-tr-group {
        height: auto;
        min-height: 40px;
        max-height: initial; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .rt-noData {
        position: initial;
        left: initial;
        top: initial;
        transform: initial; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .rt-thead.-header {
        margin: 0em;
        height: auto;
        width: auto; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .ReactTable__column-header.dealer-table-header {
        height: auto !important; }
        .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .ReactTable__column-header.dealer-table-header span,
        .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .ReactTable__column-header.dealer-table-header strong {
          word-break: break-word;
          white-space: normal;
          text-align: center;
          width: 100%;
          display: inline-block; }
        .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .ReactTable__column-header.dealer-table-header div {
          width: 100%;
          text-align: center; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .rt-tbody {
        min-width: 100% !important;
        width: initial; }
        .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .rt-tbody .rt-tr-group {
          line-height: 1em; }
          .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .rt-tbody .rt-tr-group span.showOnHover {
            visibility: hidden; }
          .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .rt-tbody .rt-tr-group:hover {
            cursor: initial; }
            .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .rt-tbody .rt-tr-group:hover span.showOnHover {
              visibility: visible; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .ReactTable__column {
        text-align: center;
        align-self: center; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .pagination-bottom .-pagination .-next button:enabled {
        background-color: #46923d;
        color: white; }
        .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .pagination-bottom .-pagination .-next button:enabled:hover {
          background-color: #377730;
          color: white; }
      .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .pagination-bottom .-pagination .-previous button:enabled {
        background-color: #46923d;
        color: white; }
        .dealer-reports-outter-container .dealers-reports-container .dealer-reports-table-container .ReactTable.-floated-table.no-overflow.all-data-table .pagination-bottom .-pagination .-previous button:enabled:hover {
          background-color: #377730;
          color: white; }
    .dealer-reports-outter-container .dealers-reports-container .dealers-reports-content-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 13px;
      margin: 0 auto;
      /*      @media only screen and (max-width: 1599px) {
        .dealer-reports-table-container {
          .ReactTable.-floated-table.no-overflow.all-data-table {
            .rt-tbody {
              min-width: unset !important;
              width: auto !important;
              margin: 0em;
            }
          }
        }
      }*/ }
      .dealer-reports-outter-container .dealers-reports-container .dealers-reports-content-container .report-full-width {
        width: 100%;
        overflow-y: auto; }
        .dealer-reports-outter-container .dealers-reports-container .dealers-reports-content-container .report-full-width .report-box-full-width {
          margin-bottom: 40px; }
      .dealer-reports-outter-container .dealers-reports-container .dealers-reports-content-container .no-data-header {
        text-align: center;
        color: #b3b3b3; }
  .dealer-reports-outter-container .quality_checks_wo_cell {
    cursor: pointer; }
