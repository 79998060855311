.EditQuestion .content .description .QuestionElement {
  display: inline-block;
  margin-right: 10px !important;
  cursor: move;
  padding: 8px;
  line-height: 14px;
  margin-bottom: 0; }

.EditQuestion .content .description .ListItem {
  width: 100%;
  margin: 0 0 5px 0;
  cursor: move; }

.EditQuestion .content .description .ui.buttons button {
  margin-right: 3px; }
