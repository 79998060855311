.ExactInvoicing {
  &__action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-buttons button:first-child {
      margin-right: 10px;
    }

    &-buttons {
      display: inline-flex;
    }

    &-checkbox {
      margin-top: 14px;

      &.checkbox-only {
        margin-top: 0;
      }
    }
  }
}

.ExactModal {
  &__content .field.recipient-field .fields {
    margin-bottom: 0;
  }
}
