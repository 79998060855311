.InlineInput_Icon {
  display: flex;
  align-items: center; }
  .InlineInput_Icon .InlineInput {
    display: inline-block; }
  .InlineInput_Icon i.red.exclamation.circle.icon.-cursor-pointer-no-color {
    margin-top: 7px; }

.solution_element_content {
  width: 250px !important; }
  .solution_element_content .item {
    margin: 0 0 5px 0;
    padding: 8px 5px;
    border: 1px solid #eee;
    border-radius: 2px; }
  .solution_element_content .item .unit {
    display: inline-block;
    width: 40%;
    border-right: 1px solid #e6e6e6;
    text-align: left; }
  .solution_element_content .item .value {
    margin-left: 1em;
    text-align: center; }

.AppointmentLists .ui.grid > .column:not(.row) {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem; }

.AppointmentLists .AppointmentChecklistGroup.AppointmentInterventions .AppointmentLists__list-title {
  padding: 0.35em 0 0.35em 0em; }
  .AppointmentLists .AppointmentChecklistGroup.AppointmentInterventions .AppointmentLists__list-title h1 {
    margin-top: 0; }
  .AppointmentLists .AppointmentChecklistGroup.AppointmentInterventions .AppointmentLists__list-title .add-intervention-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0px 13px 0px 2px;
    width: 30px;
    height: 30px;
    background-color: #21ba45;
    border-radius: 5px;
    cursor: pointer; }
    .AppointmentLists .AppointmentChecklistGroup.AppointmentInterventions .AppointmentLists__list-title .add-intervention-icon i.white.plus.icon {
      margin: 0px; }

.AppointmentLists .AppointmentLists__list-title {
  background: #1e751e;
  color: #fff;
  padding: 0.35em 1em;
  white-space: pre-wrap; }
  .AppointmentLists .AppointmentLists__list-title h1 {
    display: inline-block;
    font-size: 1.25em;
    letter-spacing: 4px;
    margin-bottom: 0;
    width: auto;
    text-transform: uppercase;
    width: calc(100% - 100px); }
    .AppointmentLists .AppointmentLists__list-title h1 span {
      text-transform: none;
      font-weight: lighter;
      font-size: 0.9em; }
  .AppointmentLists .AppointmentLists__list-title span {
    display: inline-block;
    font-size: 1.1em;
    letter-spacing: 1px;
    margin-top: 0.15em; }
  .AppointmentLists .AppointmentLists__list-title .icon {
    font-size: 1em;
    margin: 0.2em 0 0 0.5em; }
    .AppointmentLists .AppointmentLists__list-title .icon:hover {
      cursor: pointer; }
  @media (min-width: 1280px) and (max-width: 1700px) {
    .AppointmentLists .AppointmentLists__list-title .ui.grid > .row > .column.no-vat-price {
      padding-left: 0px; }
    .AppointmentLists .AppointmentLists__list-title .ui.grid > .row > .column.vat-price {
      padding-right: 0px; } }

.AppointmentLists .AppointmentChecklistGroup.AppointmentInterventions .AppointmentLists__list-title h1 {
  width: auto; }

.AppointmentLists .NoInterventions {
  border: 1px solid #eee;
  color: #959595;
  font-size: 16px;
  font-weight: 700;
  padding: 25px 0;
  text-align: center; }

.AppointmentLists .DeleteIntervention {
  width: 100%;
  display: flex;
  align-items: center; }
  .AppointmentLists .DeleteIntervention .InlineInput {
    display: inline-block;
    margin-left: 16px;
    width: calc(100% - 76px - 0.428571em); }
  .AppointmentLists .DeleteIntervention .ui.label {
    align-self: center;
    width: auto; }

.AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__group-title {
  background-color: #a2a2a2;
  color: #fff;
  padding: 0.35em 0 0.35em 0; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__group-title.-top-questions-list {
    background-color: red;
    font-size: 1.25em;
    font-weight: 700;
    text-transform: uppercase; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__group-title.tyre_title {
    display: none; }

.AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #f3f3f3;
  margin: 0 !important;
  padding: 0;
  min-height: 38px; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item.Note {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item.Note .NoteContainer {
      padding: 0;
      margin: 5px;
      background-color: #fff2ab;
      border: 1px solid #d9d9d9;
      border-radius: 0.3em; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item.Note .NoteContainer .AppointmentChecklistGroup__item__question {
        padding-top: 0;
        padding-bottom: 0; }
        .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item.Note .NoteContainer .AppointmentChecklistGroup__item__question .label {
          width: 30px;
          display: inline-flex;
          justify-content: center;
          line-height: 1.5;
          text-align: center;
          border-radius: 0;
          background-color: #f5e17b;
          color: #ce840f; }
          .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item.Note .NoteContainer .AppointmentChecklistGroup__item__question .label i.icon {
            font-size: 1.3em; }
        .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item.Note .NoteContainer .AppointmentChecklistGroup__item__question span {
          margin-top: 10px; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .six.wide.column.AppointmentChecklistGroup__item__question_remark {
    display: flex;
    align-items: center; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .four.wide.column.intervention-title {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .four.wide.column.intervention-title .ui.label {
      margin-right: 0.5em; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .four.wide.column.intervention-title .icon {
      margin: 0px; }
  @media (min-width: 1280px) and (max-width: 1700px) {
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .one.wide.column.intervention-price-container {
      font-size: 1em; } }
  @media (min-width: 1280px) and (max-width: 1700px) {
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .one.wide.column.intervention-price-container.no-vat-price {
      padding-left: 0px; } }
  @media (min-width: 1280px) and (max-width: 1700px) {
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .one.wide.column.intervention-price-container.vat-price {
      padding-right: 0px; } }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item:last-of-type {
    border-bottom: 1px solid #eee; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column {
    font-size: 13px;
    padding-bottom: 5px; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__status {
      width: 3% !important;
      font-size: 0.75em; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__question .input input,
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__question .input .button {
      padding-top: 0 !important; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__question .label {
      width: 30px;
      display: inline-flex;
      justify-content: center;
      margin-right: 0.5em;
      text-align: center; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__question .label .icon {
        margin: 0; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__question .customer-com-checker {
      margin-right: 10px;
      margin-bottom: -5px; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__modifiers .label {
      cursor: pointer;
      width: 40px;
      text-align: center;
      margin-right: 0.2em; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__modifiers .label .icon {
        font-size: 1.4em;
        margin: 0; }
      @media (max-width: 1500px) {
        .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__modifiers .label {
          font-size: 0.65em;
          margin-right: 0.32em;
          padding: 7px;
          width: 35px; } }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__modifiers .customer-comm-label {
      cursor: default; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .column.AppointmentChecklistGroup__item__mechanic_note {
      width: 8% !important; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentStatusSelector {
    float: left;
    padding: 7px;
    width: 33px;
    height: 27.16px; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentStatusSelector > span {
      color: #fff !important; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AnswerNote {
    margin-top: 0.85em; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AnswerNote .label {
      margin-right: 0.35em;
      float: left; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AnswerNote .label .icon {
        margin: 0; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AnswerNote .AnswerNote__content {
      float: left;
      margin-left: 0.2em;
      margin-top: 0.2em; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AnswerReadyStatus {
    display: inline-block;
    margin-right: 0.1em;
    width: 2em; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AnswerReadyStatus .ui.label {
      cursor: default;
      padding-left: 0;
      padding-right: 0;
      width: 2em; }
  .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media {
    background-color: #fafafa;
    border-top: 1px solid #f6f6f6;
    padding-top: 5px !important; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper {
      display: inline-block;
      margin: 0 10px 10px 0; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper img {
        background-color: #eee;
        cursor: pointer;
        height: 150px;
        width: 266px; }
        .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper img:hover {
          opacity: 0.9; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper section {
        background: #fff;
        border: 1px solid #eee;
        border-top: none;
        height: 2em;
        padding: 0.5em; }
        .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper section .icon:hover {
          cursor: pointer;
          opacity: 0.8; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Files-Wrapper {
      display: inline-block;
      margin: 0 20px 10px 0; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Files-Wrapper .file-wrapper-inner-containers {
        display: flex;
        align-items: flex-end; }
    .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper {
      display: inline-block;
      margin: 0 10px 10px 0; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper video {
        height: 150px;
        width: auto;
        transform: translateY(7px);
        position: relative;
        bottom: 7px; }
      .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper section {
        background: #fff;
        border: 1px solid #eee;
        border-top: none;
        height: 2em;
        padding: 0.5em; }
        .AppointmentLists .AppointmentChecklistGroup .AppointmentChecklistGroup__item .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper section .icon:hover {
          cursor: pointer;
          opacity: 0.8; }

.AppointmentLists .AppointmentChecklistGroup .TyreAnswer {
  padding: 0;
  width: 100%; }
  .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__head {
    width: 100%;
    padding: 0; }
    .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__head h3 {
      background: #f6f6f6;
      font-size: 1.1em;
      font-weight: 400;
      margin-bottom: 0;
      padding: 0.5em 1em;
      text-transform: uppercase;
      width: 100%; }
      .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__head h3 .TyreAnswerTable__head__label {
        margin-right: 5px; }
        .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__head h3 .TyreAnswerTable__head__label .icon {
          font-size: 1.2em;
          margin: 0;
          width: 1em; }
    .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__head .row {
      background: #eee;
      font-weight: 700;
      padding: 0.5em 0; }
    @media screen and (max-width: 1700px) {
      .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__head .tyre_profile_header {
        padding-right: 0px;
        padding-left: 25px;
        text-align: center; } }
    @media screen and (max-width: 1700px) {
      .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__head .tyre_brand_header {
        padding-left: 0px; } }
  .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body {
    margin-top: 0; }
    .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .row {
      border-bottom: 1px solid #f1f1f1;
      padding: 0; }
      .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .row .TyreAnswerTable__column {
        padding-top: 20px !important; }
        .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .row .TyreAnswerTable__column .Tyre_season_icon {
          margin-top: -5px !important;
          float: right;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .row .TyreAnswerTable__column .Tyre_season_icon .icon {
            font-size: 1.2em;
            margin: 0;
            width: 1em; }
      .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .row .TyreAnswerTable__column_statuses {
        padding-top: 15px !important; }
      .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .row .TyreAnswerTable__column_icons {
        padding-top: 5px !important; }
        .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .row .TyreAnswerTable__column_icons .AppointmentStatusSelector {
          width: auto;
          margin-top: 0; }
    .AppointmentLists .AppointmentChecklistGroup .TyreAnswer .TyreAnswerTable__body .axle-title {
      background-color: #f6f6f6;
      color: #1f1f1f;
      width: 100%;
      padding-top: 3px;
      padding-bottom: 3px;
      font-weight: 600; }

.AppointmentLists .AppointmentChecklistGroup .TyreTextArea {
  margin-top: -0.2em !important; }

.AppointmentLists .AppointmentChecklistGroup .TyreThresholdStatus {
  border-radius: 2px;
  color: #fff;
  font-weight: 700;
  padding: 0.75em 0;
  text-align: center;
  width: 4em; }
  .AppointmentLists .AppointmentChecklistGroup .TyreThresholdStatus.-answer-status.-status-0 {
    background-color: green; }
  .AppointmentLists .AppointmentChecklistGroup .TyreThresholdStatus.-answer-status.-status-1 {
    background-color: green; }
  .AppointmentLists .AppointmentChecklistGroup .TyreThresholdStatus.-answer-status.-status-2 {
    background-color: orange; }
  .AppointmentLists .AppointmentChecklistGroup .TyreThresholdStatus.-answer-status.-status-3 {
    background-color: red; }
  .AppointmentLists .AppointmentChecklistGroup .TyreThresholdStatus.-answer-status.-status-4 {
    background-color: grey; }

.AppointmentLists .AppointmentChecklist.-list-visibility-all .AppointmentChecklistGroup__item,
.AppointmentLists .AppointmentChecklist.-list-visibility-all .TyreAnswerTable__row {
  display: flex; }

.AppointmentLists .AppointmentChecklist.-list-visibility-nok-statuses .AppointmentChecklistGroup__item.-item-status-0, .AppointmentLists .AppointmentChecklist.-list-visibility-nok-statuses .AppointmentChecklistGroup__item.-item-status-4 {
  display: none; }

.AppointmentLists .AppointmentChecklist.-list-visibility-nok-statuses .AppointmentChecklistGroup__item.-item-status-1, .AppointmentLists .AppointmentChecklist.-list-visibility-nok-statuses .AppointmentChecklistGroup__item.-item-status-2, .AppointmentLists .AppointmentChecklist.-list-visibility-nok-statuses .AppointmentChecklistGroup__item.-item-status-3 {
  display: flex; }

.AppointmentLists .Checks-Placeholder {
  color: #9f9f9f;
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 2em;
  padding: 50px 0;
  text-align: center; }
  .AppointmentLists .Checks-Placeholder section {
    margin-top: 25px; }
  .AppointmentLists .Checks-Placeholder .icon {
    font-size: 3em;
    margin-bottom: 15px; }

.AppointmentLists.-is-editable-false .AppointmentChecklistGroup__item__modifiers.-answer-status {
  cursor: not-allowed; }
  .AppointmentLists.-is-editable-false .AppointmentChecklistGroup__item__modifiers.-answer-status .AppointmentStatusSelector {
    pointer-events: none; }

.AppointmentLists.-is-editable-false .ThumbToggle {
  cursor: not-allowed; }
  .AppointmentLists.-is-editable-false .ThumbToggle .ui.label {
    pointer-events: none; }

.AppointmentLists.-is-editable-false .TyreAnswerTable__column_icons.-answer-status-column {
  cursor: not-allowed; }
  .AppointmentLists.-is-editable-false .TyreAnswerTable__column_icons.-answer-status-column .AppointmentStatusSelector {
    pointer-events: none; }

.outter-container {
  border-left: 1.2px solid #f2f2f2;
  border-right: 1.2px solid #f2f2f2; }

.ui.grid + .grid.outter-container {
  margin-top: 0em; }

.ui.grid > .row.notes-container {
  margin: 0.3em;
  align-items: center;
  padding: 0.6em 0em 0.35em 0em; }

.ui.grid > .row.notes-container:last-child {
  margin: 0.3em 0.3em 1em 0.3em; }

.notes-container {
  border: 1px solid #d9d9d9;
  border-radius: 0.3em; }
  .notes-container .edit-note-container {
    display: inline-block;
    border: 1px solid #2185d0;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 3px 10px;
    cursor: pointer;
    color: #2185d0;
    margin-right: 5px; }

.ui.grid > .row.notes-remarks-header-container {
  background-color: #f7e99e;
  padding: 0.5em 0.3em;
  margin-bottom: 0.5em; }
  .ui.grid > .row.notes-remarks-header-container .notes-remarks-header-icon {
    color: #ce841d;
    margin-right: 0.35em;
    font-size: 1.1em; }
  .ui.grid > .row.notes-remarks-header-container .notes-remarks-header-title {
    color: #ce841d;
    font-size: 1em; }
  .ui.grid > .row.notes-remarks-header-container .notes-remarks-header-title-small {
    color: #fff;
    font-size: 0.8em; }

.notest-mechanic-remarks-title {
  color: #000;
  font-weight: 600;
  margin-bottom: 1em;
  word-break: break-all; }

.dms-notes-description-container {
  display: flex;
  align-items: center;
  padding: 0.55em 0.3em; }

.notes-timestamp {
  color: #726c6c;
  margin-left: 10px; }

.notes-updated-by {
  font-size: 12px;
  margin-top: 5px;
  font-weight: normal;
  color: #767777; }

.notes-description {
  color: #726c6c;
  word-break: break-all; }

.CustomerSmile {
  font-size: 14px; }
  .CustomerSmile .car.icon {
    margin-left: 17px;
    margin-right: 9px; }

.Snoozer.-is-editable-false .Snoozer-Content {
  cursor: not-allowed; }
  .Snoozer.-is-editable-false .Snoozer-Content button {
    pointer-events: none; }

.Pinner.-is-editable-false div {
  cursor: not-allowed; }
  .Pinner.-is-editable-false div button {
    pointer-events: none; }

.AppointmentChecklistGroup__header span {
  font-weight: 600;
  font-size: 0.9em;
  text-transform: capitalize; }

.intervention-elements-toggle-container {
  min-width: 14px;
  width: 14px; }
  .intervention-elements-toggle-container svg.svg-inline--fa.fa-caret-down.fa-w-10.intervention-elements-toggle-btn,
  .intervention-elements-toggle-container svg.svg-inline--fa.fa-caret-right.fa-w-6.intervention-elements-toggle-btn {
    cursor: pointer; }

.intervention-element-container {
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important; }
  .intervention-element-container .ui.grid .row.intervention-elements {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
    .intervention-element-container .ui.grid .row.intervention-elements .column {
      padding-bottom: 0; }
    .intervention-element-container .ui.grid .row.intervention-elements .intervention-element-description {
      display: flex;
      align-items: center;
      margin-left: 15px; }
      .intervention-element-container .ui.grid .row.intervention-elements .intervention-element-description .arrow-to-right {
        width: 32px;
        text-align: center;
        margin-right: 10px; }
  .intervention-element-container .ui.grid .intervention-elements:last-child {
    border-bottom: none; }
  .intervention-element-container .ui.grid .intervention-element-text {
    flex: 1; }
  .intervention-element-container .ui.grid .intervention-element-icon .ui.label {
    min-width: 32px;
    width: 32px;
    text-align: center; }
  .intervention-element-container .ui.grid .intervention-element-icon svg {
    color: rgba(0, 0, 0, 0.6); }
  .intervention-element-container .ui.grid .six.wide.column.private-note-container,
  .intervention-element-container .ui.grid .seven.wide.column.private-note-container {
    display: flex;
    align-items: center;
    word-break: break-all; }
    .intervention-element-container .ui.grid .six.wide.column.private-note-container .private-note-icon,
    .intervention-element-container .ui.grid .seven.wide.column.private-note-container .private-note-icon {
      display: inline-block;
      margin-right: 5px; }
      .intervention-element-container .ui.grid .six.wide.column.private-note-container .private-note-icon i,
      .intervention-element-container .ui.grid .seven.wide.column.private-note-container .private-note-icon i {
        margin: 0px;
        font-size: 13px; }
  .intervention-element-container .ui.grid .one.wide.column.intervention-element-quantity-container {
    padding-left: 0px; }
  @media (min-width: 1280px) and (max-width: 1700px) {
    .intervention-element-container .one.wide.column.price {
      padding-left: 0px; } }

.row.declined-intervention-container {
  padding-left: 14px !important;
  padding-right: 10px !important; }
  .row.declined-intervention-container .stretched.column.declined-intervention {
    background-color: #f9fafb;
    border: 1px solid #e8e8e8;
    font-weight: 600;
    padding: 10px; }
